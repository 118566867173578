.partners img {
  height: 3rem;
  margin: 1rem;
  z-index: 2;
}

#partner0 img {
  height: 6rem;
}

#partner4 img {
  height: 2rem;
}

#partner7 img {
  height: 6rem;
}

#partner8 img {
  height: 4rem;
}

.partners div{
  display: inline-block;
}

.partners div:nth-of-type(2) {
  background-color: #000;
  border-top-left-radius: 180%;
  border-bottom-right-radius: 180%;
  border-bottom-left-radius: 90%;
  border-top-right-radius: 90%;
}

.partners h3 {
  font-family: 'DM Serif Text';
  font-variant: small-caps;
  color: #000;
  font-size: 3rem;
  padding-top: 3rem;
}

.partners {
  width: 100%;
  min-height: 30vh;
  max-height: fit-content;
  opacity: 0;
  background: linear-gradient(to bottom, #dfe9f3 0%, white 40%);
  animation-name: pageIn;
  animation-duration:1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  margin: 20vh 0 5vh 0;
}

.partners div a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
  z-index: 3;
}

.partners div {
  position: relative;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Vyvo-logo {
  height: 40vmin;
  pointer-events: none;
  margin-bottom: 30px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s 8s ease-in;
  }
  
}

.App-header {
  background-image: url('./background_image.jpg');
  background-size: cover;
  background-color: #282c34;

  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header::before {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  content: '';
  background: hsla(0, 0%, 100%, .5);
  opacity: 0.6;
  z-index: -1;
}

.App-header h2 {
  font-family: 'Quattrocento';
  color: #fff;
  font-size: 250%;
  font-style: normal;
  font-variant: normal;
  width: 33%;
  height: 300px;
  position: absolute;
  top: 29%;
  left: 57%;
  z-index: 10;
  transform: rotateZ(-20deg);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

footer {
  
  background: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-top: 250px;
  min-height: 400px;
}

header {
  background: hsla(0, 0%, 70%, .2);
  min-height: 400px;
}

header h2, footer h2 {
  -webkit-text-stroke: 1px black;
  font-variant: small-caps;
}
header p {
  -webkit-text-stroke: 0.1px black;
}

header {
  border-top: 1px #000 solid;  
  border-bottom: 1px #000 solid;
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.021), 
  0px 0px -14px rgba(255, 255, 255, 0);
}
nav {
  position: sticky;
  background: hsla(0, 0%, 100%, .7);
  top: 0;
  font-variant: small-caps;
  z-index: 13;
  font-size: x-large;
}

nav img {
  height: 30px;
  margin: 0 10px 0 10px;
}

.App-header {
  background-color: #282c34;
  opacity: 0.7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  position: relative;
  display: flex;
  margin: 5vh 0vw;
  padding: 0;
  min-width: 70vw;
  min-height: 1vh;
  align-items: center;
  animation: pageIn 1s forwards;
}

.kontakt img{
}

.kontakt h3 {
  margin-top: 20px;
}

.kontakt p {
  font-size: x-large;
}

.kontakt p:first-child {
  text-align: center;
  font-family: 'DM Serif Text';
}

.kontakt p{
  text-align: left;
  font-family: 'Quattrocento';
  font-weight: 600;
}


.kontakt a {
  color: #000;
  font-style:none;
}

.kontakt a:hover {
  color: #000;
  font-style:none;
}

.title {
  top: 0;
  background-color: #FFF;
  display: block;
  width: 100%;
  height: fit-content;
}

.kontakt {
  color: #000;
  position: relative;
  min-width: 70vw;
  min-height: fit-content;
  background: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
  animation: pageIn 1s forwards;
}

.watermark { 
    z-index:-1;
    opacity: 0.3;
    position: absolute;
    top: 5vh;
    left: 5vw;
    width: 700px;
    height: 700px;
    transform: rotateZ(-30deg);
    background-clip: border-box; 
    background-repeat: no-repeat;
}
.about-us {
  color: #000;
  position: relative;
  min-width: 70vw;
  min-height: fit-content;
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  animation: pageIn 1s forwards;
}

.about-us h1 {
  font-family: 'DM Serif Text';
  font-variant: small-caps;
  font-size: 3rem;
  font-weight: 600;
  margin: 10vh 0;
}

.about-us h2 {
  font-family: 'Quattrocento';
  font-size: 2rem;
  font-weight: 600;
  margin: 3vh 0;
}

.about-us h3 {
  font-family: 'Quattrocento';
  font-size: 2.5rem;
  font-weight: 900;
}

.about-us a, .about-us a:hover {
  color: #000;
  font-weight: 900;
}


.about-us p {
  margin: 4vh 0;
}

.about-us p:nth-child(2) {
  font-family: 'Dancing Script';
  font-size: xx-large;
  font-weight: 600;
}

.about-us p:nth-child(3){
  font-family: 'DM Serif Text';
  font-size: xx-large;
  font-weight: 600;
}

.about-us p:nth-child(4){
  font-family: 'Quattrocento';
  font-size: xx-large;
  font-weight: 600;
}

.about-us-logo {
  height: 40vmin;
  pointer-events: none;
  opacity: 1;
}
.about-us-logotype {
  height: 40vmin;
  pointer-events: none;
  opacity: 1;
  margin: 30px 0;
}

.preface {
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;
  grid-template-columns: 30% 35% 30%;
  grid-gap: 10px;
  padding: 10px;
  position: relative;
  min-width: 70vw;
  min-height: 40vh;
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
}


.preface h3 li {
  list-style-type: none;
}

.preface h3 {
  min-width: 300px;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-size: x-large;
  margin: 15% 0;
  text-align: center;
}

.preface div div {
  margin: 0 10%;
}

.preface div:nth-child(3) h3:nth-child(2), .description p:nth-child(3), .description p:nth-child(6){
  font-family: 'DM Serif Text';
}

.preface div:nth-child(1) div:nth-child(1) h3:nth-child(2) {
  font-family: 'DM Serif Text';
}

.preface div:nth-child(1) h3:nth-child(1), .preface div:nth-child(3) h3:nth-child(1), .description p:nth-child(2), .description p:nth-child(5){
  font-family: 'Dancing Script';
  font-size: xx-large;
}

.preface div:nth-child(2) div:nth-child(1) h3:nth-child(1), .description p:nth-child(4), .description p:nth-child(7){
  font-family: 'Quattrocento';
}

.description p:nth-child(2), .description p:nth-child(5){
  text-align: center;
  margin: 5% 10%;
  width: 80%;
}
.description p:nth-child(3), .description p:nth-child(6){
  text-align: left;
  width: 90%;
  margin: 5%;
  tab-size: 2.em;
}

.preface div:nth-child(2) div:first-child h3 {
  font-size: x-large;
}

.preface div:nth-child(1) {
  vertical-align: middle;
  opacity: 0;
  animation: pageIn 3s forwards;
  animation-delay: 0.5s;
}

.preface div:nth-child(2) {
  vertical-align: middle;
  opacity: 0;
  animation: pageIn 3s forwards;
  animation-delay: 1s;
}
.preface div:last-child {
  vertical-align: middle;
  opacity: 0;
  animation: pageIn 3s forwards;
  animation-delay: 1.3s;
}

.content h1, .preface h1, .gallery h1 {
  font-variant: small-caps;
  margin: 0 0 5vh 0;
  text-align: center;
  font-family: 'DM Serif Text';
}

.picture {
  border: 0px solid #000; 
}

.picture div {
  height: 600px;
}

.picture img {
  height: 500px;
  box-shadow: 3px 15px 50px rgba(45, 43, 43, 0.9);


}
.description {
  opacity: 0;
  text-align: justify;
  animation: pageIn 1s forwards;
  animation-delay: 0.3s;
  height: 600px;
  padding: 50px 50px;
  
  vertical-align: middle;
  color: #FFF;
}

.description p {
  font-size: 150%;
}

.description h2 {
  font-variant: small-caps;
  font-weight: 600;
  margin-bottom: 20px;
}



.footer {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
  margin-top: 50vh;
}

footer a, footer p {
  color: #000;
  font-size: x-large;
  font-variant: small-caps;
  font-weight: 600;
  display: block;
}


.footer-contact {

  margin: 40px 15px;
}

.footer-contact a {
  font-weight: 600;
  font-size: xx-large;
}
.social-ico a:active{
	color: #FFF;
	transform: scale(0.8);
	text-decoration: none;
}

.social-ico p{
  display: inline;
  margin: 0 15px;
}


.social-ico img {
	height: 150px;
	width: 150px;
	margin: 40px 0;
  filter: grayscale(1);
}

.gallery-insta {
  filter: grayscale(1);  
  vertical-align: top;
  width: 15vw;
  height: 15vw;
  margin: 15px;
  display: inline-grid;
}

.gallery-insta:hover {
  filter: none;
}


.nav-logo img{
	height: 100px;
	width: 100px;
  margin: 0 7px;
}

.footer-logo img{
	height: 200px;
	width: 200px;
  margin: 0 4px;
}

.footer-logo p{  
  display: inline;
  font-variant: small-caps;
}

div .thumbnail
{
  min-height: 140px;
	align-content: right;
  margin: 0 0;
}

.miniature {
  width: 15vw;
  height: 15vw;
  margin: 15px;
  display: inline-grid;
  border-color: #000;  
  border-radius: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  animation: rotateIn 1s 0.2s forwards;
}

.miniature:hover {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  cursor: pointer;
  animation: rotateIn 0.3s 0 backwards;
  background-size: 300%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.9);
}

.miniature:active {
  margin: 15px;
  display: inline-grid;
  border-color: #000;
  opacity: 1;
  transition: 1s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
}

@-webkit-keyframes rotateIn{
  from {
    border-radius: 100%;
  }
  to { 
    border-radius: 0%;
  }
}

@keyframes rotateIn{
  from { 
    border-radius: 100%;
   }
  to { 
    border-radius: 0%;
  }
}


.gallery {
  position: relative;
  margin: 10vh 0 7vh 0;
  padding: 2vw;
  min-width: 70vw;
  min-height: 40vh;
  opacity: 0;
  animation-name: pageIn;
  animation-duration: 1s;
  animation-delay: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
@-webkit-keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes pageIn{
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media only screen and (max-width: 600px) {

  .App-header {
    background-image: url('./background_image_square.jpg');
  }
  .App-header h2 {
    display: none;
  }
  .description {
    margin: 5vh 1vw 5vh 1vw;
    padding: 3%;
    text-align: justify;
    height: fit-content;
    font-size: 100%;
    border: none;
  }
  .description p, .about-us p, .about-us p:nth-child(3), .about-us p:nth-child(4){
    font-size: 130%;
  }
  .description p:nth-child(2), .description p:nth-child(5){
    text-align: center;
    margin: 5% 0;
    width: 100%;
  }
  .description p:nth-child(3), .description p:nth-child(6){
    text-align: justify;
    width: 100%;
    margin: 5% 0;
    tab-size: 2.em;
  }
  .about-us h2{
    margin: 3vh 0 1vh 0
  }

  .about-us h1{
    margin: 10vh 0 2vh 0
  }
  .footer { 
    margin-top: 0;
  }

  header {
    min-height: 15px;
  }

  .gallery {
    margin: 0 0 7vh 0;
  }

  .content {
    margin: 5vh 5vw ;
    min-width: 80vw;
  }

  .footer, .preface {
    display: inline-block;
  }

  .preface h3 {
    font-size: larger;

  }
  .miniature {
    width: 30vw;
    height: 30vw;
  }  
  .picture div {
    height: 30vh;
   }
   .watermark {
    top: 35vh;
    left: 5vw;
    height: 300px;
    width: 300px;
   }
   .gallery-insta p {
    visibility: hidden;
   }

   .partners {
    background: linear-gradient(to bottom, #dfe9f3 0%, white 20%);
   }


}






