
.intro,
.intro a{
  color:#fff;
}
/* customizable snowflake styling */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes 
snowflakes-fall
{0%{top:-10%}
100%{top:100%}
}
@-webkit-keyframes snowflakes-shake{
    0%{
        -webkit-transform:translateX(0px);
        transform:translateX(0px)
    }
    50%{
        -webkit-transform:translateX(80px);
        transform:translateX(80px)
    }
    100%{
        -webkit-transform:translateX(0px);
        transform:translateX(0px)
    }
}
@keyframes snowflakes-fall
{
    0% {top:-10%}
    100% {top:100%}
}
@keyframes snowflakes-shake
{
    0%{transform:translateX(0px)}
    50%{transform:translateX(80px)}
    100%{transform:translateX(0px)}
}


.snowflake{
    position:fixed;
    top:-10%;
    z-index:9999;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;cursor:default;
    -webkit-animation-name:snowflakes-fall,snowflakes-shake;
    -webkit-animation-duration:10s,3s;
    -webkit-animation-timing-function:linear,ease-in-out;
    -webkit-animation-iteration-count:infinite,infinite;
    -webkit-animation-play-state:running,running;
    animation-name:snowflakes-fall,snowflakes-shake;
    animation-duration:10s,3s;
    animation-timing-function:linear,ease-in-out;
    animation-iteration-count:infinite,infinite;
    animation-play-state:running,running
}
.snowflake:nth-of-type(0){
    left:1%;-webkit-animation-delay:0s,0s;
    animation-delay:0s,0s
}
.snowflake:nth-of-type(1){
    left:10%;
    -webkit-animation-delay:1s,1s;
    animation-delay:1s,1s
}
.snowflake:nth-of-type(2){
    left:20%;
    -webkit-animation-delay:6s,.5s;
    animation-delay:6s,.5s}

.snowflake:nth-of-type(3){
    left:30%;
    -webkit-animation-delay:4s,2s;
    animation-delay:4s,2s;
}

.snowflake:nth-of-type(4){
    left:40%;
    -webkit-animation-delay:2s,2s;
    animation-delay:2s,2s
}.snowflake:nth-of-type(5){
    left:50%;
    -webkit-animation-delay:8s,3s;
    animation-delay:8s,3s}
.snowflake:nth-of-type(6){
    left:60%;
    -webkit-animation-delay:6s,2s;
    animation-delay:6s,2s}
.snowflake:nth-of-type(7){
    left:70%;
    -webkit-animation-delay:2.5s,1s;
    animation-delay:2.5s,1s}
.snowflake:nth-of-type(8){
    left:80%;
    -webkit-animation-delay:1s,0s;
    animation-delay:1s,0s}
.snowflake:nth-of-type(9){
    left:90%;
    -webkit-animation-delay:3s,1.5s;
    animation-delay:3s,1.5s}


.god-jul-popup{
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 100;
	text-align: center;
	border: #000 2px solid;
    animation-duration: 5s;
    opacity: 1;
    animation-timing-function: ease-in-out;
}
.god-jul{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 405px;
    height: 405px;
    background: url('./BNvyvo405.png');
    background-position: 50% 50%;
    background-size: cover;
	z-index: 12;
	text-align: center;
	border: #000 2px solid;
    transform: translate(-50%, -50%);
}

.god-jul-popup p:last-child{
	color: #FFF;
	font-size: 24px;
	margin-top: 1.4rem;
}

.god-jul-popup-close-button{
	position: absolute;
	cursor: pointer;
	top: 1rem;
	right: 1rem;
	font-size: 30px;
	font-weight: 500;
	margin-top: 0;
	text-shadow: #000 2px 2px 2px;
	color: #FFF;
}

.god-jul-popup-close-button:hover{
	text-shadow: #000 2px 2px 2px;
	color: #555;
}

.god-jul-popup-disappear{
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 0;
	text-align: center;
	border: #000 2px solid;
	animation-name: hide-animation;
    animation-duration: 1s;
    opacity: 0;
    animation-timing-function: ease-in-out;
}

@keyframes popup {
    from {
        opacity: 0;
        display: none;
    }
    to {
        opacity: 1;
        display: initial;
    }
  }
  @keyframes hide-animation {
    0% {
        z-index: 100;
        display: block;
        opacity: 1;
    }
    100% {
        z-index: 0;
        display: none;
        opacity: 0;
    }
  }
  @media only screen and (max-width: 600px) {
    .god-jul{
        width: 405px;
        height: 405px;
    }
}